.root {
    display: grid;
    margin: calc(6 * var(--spacing)) 0;
    gap: calc(2 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);
    row-gap: calc(2 * var(--spacing));
}

.title {
    margin: calc(4 * var(--spacing)) 0 calc(2 * var(--spacing));
}

.collapsible {
    display: grid;
    margin-top: calc(4 * var(--spacing));
    gap: calc(2 * var(--spacing));
    grid-template-columns: minmax(0, 1fr);
    row-gap: calc(2 * var(--spacing));
}
